<template>
  <v-app style="background-color: #f2f2f2">
    <v-layout row wrap align-center justify-center>
      <v-flex lg4 md5 sm7 xs7 class="text-center">
        <img src="@/assets/logo_onebox-horizontal-png.png" width="45%" height="45%" alt="OneBox" />
        <br />
        <br />
        <v-progress-linear
          v-if="fail_ === false"
          background-color="light-blue lighten-4"
          color="success"
          indeterminate
          rounded
        ></v-progress-linear>
        <br />
        <p v-if="fail_ === false" class="text-center">รอสักครู่</p>
        <p v-else class="text-center"><v-icon color="error">error</v-icon>&nbsp;{{errormessage}}</p>
      </v-flex>
    </v-layout>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import VueCookies from "vue-cookies";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data: function() {
    return {
      fail_: false,
      errormessage: "",
    };
  },
  created() {},
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "role_level"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataLoginDefault",
    ]),
  },
  methods: {
    reset_login() {
      this.$store
        .dispatch("authorize_expired")
        .then(this.login())
        .catch((error) => {
          console.log(error);
        });
    },
    async login() {
      //เช็คว่ามี accesstoken มั้ย
      if (!this.$route.query.share_token) {
        this.fail_ = true;
        this.errormessage = "Accesss Denied !!"        
      } else {
        let _sharedtoken = this.$route.query.share_token;
        let _bizid = this.$route.query.biz_id;
        let _azure = this.$route.query.azure;

        let payload = {
          shared_token: _sharedtoken,
        };

        // 1 ยิง API login_business_sharedtoken
        let check_auth = await this.$store.dispatch("authorize_login_business_sharedtoken", payload);
        if (check_auth.status === "Access Granted") {
          // ส่ง event ไปยัง Google Analytics
          this.$gtag.event('login', {
            event_category: "azure",
          });

          // if (_azure === "1") {
          //   VueCookies.set("azure", _azure, "1h")
          //   localStorage.setItem("loginType", "azure");
          //   this.$router.push({ name: "myfiles" });
          // } else {
            // เช็คว่ามี bizid มั้ย
            let objIndex = this.dataBusinessProfile.findIndex((obj) => obj.id === _bizid);
            if (objIndex === -1) {  // ไม่มี bizid
              this.fail_ = true;
              this.errormessage = "คุณไม่ได้อยู่ภายในบริษัท"
            } else {  // มี bizid
              // VueCookies.remove("azure");
              // localStorage.setItem("loginType", "sharedtoken");

              if (_azure === "1") {
                VueCookies.set("azure", _azure, "1h")
                localStorage.setItem("loginType", "azure");
              } else {
                VueCookies.remove("azure");
                localStorage.setItem("loginType", "sharedtoken");
              }
  
              let payload_bu = {
                taxid: this.dataBusinessProfile[objIndex].id_card_num,
                accesstoken: window.atob(VueCookies.get("token")),
                biz_id: _bizid,
                branch_no: this.dataBusinessProfile[objIndex].branch_no || '00000',
              };
              // 2 ยิง API Login Business
              this.$store.dispatch("switchaccount_business", payload_bu).then(async (msg) => {
                if (msg.status === "Business Access Granted") {
                  this.$router.push({ name: "directory", params: { id: this.dataAccountActive.business_info.my_folder_id } });
                }
              });
            }
          // }
        } else {
          this.fail_ = true;
          this.errormessage = "ไม่สามารถ login ได้ !!"            
          setTimeout(() => {
            this.$router.push("/");
          }, 3000);
        }
      }
    }
  },
  mounted() {
    this.reset_login();
  }
}
</script>
